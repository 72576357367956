.popup-content {
    margin: auto;
    background-color: rgba(0,0,0,0.4);
    width: 85%;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

@media (max-width: 780px) {
  .popup-content {
    width: 100%;
  }
}

