body {
    margin: 0 auto;
    background-color: #272f31;

    @font-face {
        font-family: 'Baskerville Old Face Bold';
        src: url('./fonts/baskvill.ttf') format('truetype');
        /* Add other font properties here if needed */
    }
    @font-face {
        font-family: 'Josefin Sans Light';
        src: url('./fonts/JosefinSans-Light.ttf') format('truetype');
        /* Add other font properties here if needed */
    }

}



@import "./banner";
@import "./gallery";
@import "./nav";
@import "./about";
@import "./footer";
@import "./popup";
@import "./contact";
@import "./map";
@import "./hamburger";
@import "./form";
