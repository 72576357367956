.aboutSection {
    background-color: #272f31;
    display: flex;
    justify-content: center;
    color: white;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    p {
        font-size: 18px;
        font-family: 'Josefin Sans Light';
    }
    h2 {
        font-family: 'Josefin Sans Light';
    }
}